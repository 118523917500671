// Font
@import url(https://fonts.bunny.net/css?family=cambay:400,700);

// Bulma
@import 'bulma/css/bulma.css';

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// Normalize
@import '../../css/normalize.css';

// Variables
@import 'variables';

// Mixins
@import 'mixin';

html {
    background: hsla(186, 33%, 94%, 1);
    background: linear-gradient(90deg, hsla(186, 33%, 94%, 1) 0%, hsla(216, 41%, 79%, 1) 100%);
}

body {
    font-family: 'Cambay', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
}    

.nav-container {    
    @include container;
    
    display: flex;
    flex-direction: column;
    align-items: right;
    
    padding: 1em;
}

.nav {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: .7em;
    color: darken($second-color, 20%);
    margin-bottom: 2em;
    
    li {
        transform: scale(1);
        transition: all .2s ease-in-out;
    }
    
    li:hover {
        transform: scale(1.05);
    }
    
    a {
        text-decoration: none;
        color: rgb(68, 68, 68);
        transition: all .2s ease-in-out;
    }
    
    a:hover {
        color: black;
    }
}

.admin-container {
    @include container;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-top: 15%;
    
    img {
        max-width: 200px;
        height: auto;
        margin-bottom: 1em;
    }
}

.options-container {
    @include container;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    
    margin-top: 20%;
    
    a {
        text-decoration: none;
        color: darken($second-color, 20%);
        margin-bottom: .5em;
        transform: scale(1);
        transition: all .2s ease-in-out;
    }
    
    a:hover {
        transform: scale(1.05);
        color: $second-color;
    }
}

.checkbox {
    color: $second-color
}

.loader {
    position: absolute;
    top: 30%;
    left: 50%;
    margin-left: -40px;
    height: 80px;
    width: 80px;
    border-top-color: hsla(216, 41%, 79%, 1);
    border-right-color: black;
}

.ql-editor {
    background-color:  white !important;
    min-height: 120px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.ql-toolbar {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.ql-container {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.inline-text {
    margin: 0 !important;
}

.inline-button {
    display: inline-block;
    margin-left: auto;
}

.section-title {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}





